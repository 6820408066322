import * as React from "react";

import { Box, Heading, Text, useMediaQuery } from "@chakra-ui/react";

import MainLayout from "@/components/Layouts/MainLayout";

export default function NotFound() {
  const [isLargerThanMd] = useMediaQuery("(min-width: 48em)", {
    ssr: false,
    fallback: true,
  });

  return (
    <MainLayout isLargerThanMd={isLargerThanMd}>
      <Box as="section" id="not-found" my={100} width="90%" mx="auto">
        <Heading fontFamily="Geomanist">UH OH! 404</Heading>

        <Text fontFamily="Geomanist">This page does not exist.</Text>
      </Box>
    </MainLayout>
  );
}
